import Api from "./base";

class TrainerApi extends Api {
	getQuestionById = async (id) => {
		return await this.get(`/questions/${id}`);
	};
	getQuestions = async () => {
		return await this.get(`/questions`);
	};
	updateQuestionById = async (id, data) => {
		return await this.put(`/questions/${id}`, data);
	};
	createQuestion = async (data) => {
		return await this.post(`/questions`, data);
	};
	deleteQuestionById = async (id) => {
		return await this.delete(`/questions/${id}`);
	};
	trainQuestion = async (id) => {
		return await this.get(`/questions/${id}/train`);
	};
}

const trainerApi = new TrainerApi();
export default trainerApi;
