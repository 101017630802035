// import { showToast, showSuccess, showMessage } from "../App";
import authApi from "../api/auth";
import {
	addTokenToLocalStorage,
	removeTokenFromLocalStorage,
} from "../api/base";
import { showError } from "../App";

class AuthService {
	login = async (data) => {
		console.log("Credential:", data);
		const res = await authApi.login(data);
		if (res.success) {
			addTokenToLocalStorage(res.data.session.access_token);
			// showSuccess("Logged in successfully", res);
		} else {
			showError(res.detail, "error");
		}
		return res;
	};
	logout = async () => {
		const res = await authApi.logout();
		// console.log(res);
		// if (res.success)
		{
			removeTokenFromLocalStorage();
			// showToast("Logged out successfully");
		}
		return res;
	};
}

const authService = new AuthService();
export default authService;
