import Api from "./base";

class ScraperApi extends Api {
	// Dedicated Scrapers like Fiverr, Freelancer
	getScrapers = async () => {
		return await this.get(`/scrapers`);
	};

	refreshScraper = async (id) => {
		return await this.post(`/scrapers/${id}/refresh`);
	};

	// Generic Scraper
	scrapeUrl = async (url) => {
		console.log(url);
		return await this.post(`/adminUrlTrain?url=` + url);
	};

	getScrapedUrls = async () => {
		return await this.get(`/adminUrlTrain`);
	};
}

const scraperApi = new ScraperApi();
export default scraperApi;
