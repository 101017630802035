import { useEffect, useState } from "react";
import scraperApi from "../../api/scraper";
import { showError, showSuccess } from "../../App";
import {
	Autocomplete,
	Box,
	Button,
	Card,
	CardHeader,
	Divider,
	FormControl,
	MenuItem,
	Select,
	TextField,
	Typography,
} from "@mui/material";
import { Refresh } from "@mui/icons-material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEarthAmericas } from "@fortawesome/free-solid-svg-icons";
import config from "../../config";
import { LoadingButton } from "@mui/lab";
export default function Scraper() {
	const [scrapers, setScrapers] = useState([
		{
			id: 1,
			name: "Fiverr",
			url: "https://www.fiverr.com/",
			scraped_at: "2021-10-10T10:10:10",
		},
		{
			id: 2,
			name: "Upwork",
			url: "https://www.upwork.com/",
			scraped_at: "2021-10-10T10:10:10",
		},
	]);

	const [scrapedUrls, setScrapedUrls] = useState([]);

	const [newUrl, setNewUrl] = useState("");
	const [selectedScraper, setSelectedScraper] = useState("");
	const [loadingScraper, setLoadingScraper] = useState(false);
	const [loadingUrl, setLoadingUrl] = useState(false);

	const handleRefresh = async () => {
		setLoadingScraper(true);
		const res = await scraperApi.refreshScraper(selectedScraper.id);
		if (res.success) {
			setScrapers((prev) =>
				prev.map((scraper) =>
					scraper.id === selectedScraper.id
						? { ...scraper, ...res.data }
						: scraper
				)
			);
			showSuccess("Successfully scraped " + selectedScraper.name);
		} else {
			showError("Failed to scrape right now. Please try again later.");
		}
		setLoadingScraper(false);
	};

	const handleUrlScrape = async (e) => {
		e.preventDefault();
		setLoadingUrl(true);
		const res = await scraperApi.scrapeUrl(newUrl);
		if (res.success) {
			setScrapedUrls((prev) => [...prev, res.data]);
			showSuccess("Successfully scraped the url");
		} else {
			showError("Failed to scrape the url. Please try again later.");
		}
		setLoadingUrl(false);
	};

	const fetchScrapers = async () => {
		const res = await scraperApi.getScrapers();
		if (res.success) {
			setScrapers(res.data);
		} else {
			// showError("Failed to fetch scrapers. Please try again later.");
		}
	};

	const fetchScrapedUrls = async () => {
		const res = await scraperApi.getScrapedUrls();
		if (res.success) {
			setScrapedUrls(res.data);
		} else {
			// showError("Failed to fetch scraped urls. Please try again later.");
		}
	};

	useEffect(() => {
		fetchScrapers();
		fetchScrapedUrls();
	}, []);

	return (
		<Box
			className="p-5 mx-auto flex items-center flex-col gap-10 justify-center w-full"
			sx={{
				minHeight: `calc(100vh - ${config.topbarHeight}px)`,
			}}
		>
			{/* <Card className="w-full max-w-4xl p-3 flex flex-col gap-3">
				<Typography variant="h5">Dedicated Scrapers</Typography>
				<Divider />
				<div className="flex flex-row gap-5">
					<Box className="w-2/3">
						<FormControl fullWidth>
							<Select
								labelId="scraper-select-label"
								id="scraper-select"
								value={selectedScraper}
								onChange={(e) =>
									setSelectedScraper(e.target.value)
								}
								displayEmpty
								size="small"
							>
								<MenuItem value="">
									<em>Select a scraper</em>
								</MenuItem>
								{scrapers.map((scraper) => (
									<MenuItem
										key={scraper.id}
										value={scraper.id}
									>
										{scraper.name}
									</MenuItem>
								))}
							</Select>
						</FormControl>
					</Box>
					<LoadingButton
						variant="contained"
						color="primary"
						className="w-1/3"
						onClick={handleRefresh}
						startIcon={<Refresh />}
						disabled={!selectedScraper}
						loading={loadingScraper}
						// loadingPosition="start"
					>
						Refresh
					</LoadingButton>
				</div>
			</Card> */}

			<Card className="w-full max-w-4xl p-3 flex flex-col gap-3">
				<Typography variant="h5">Generic Scraper</Typography>
				<Divider />
				<form
					onSubmit={handleUrlScrape}
					className="flex flex-row gap-5"
				>
					<Autocomplete
						disablePortal
						id="combo-box-demo"
						size="small"
						className="w-2/3"
						options={scrapedUrls}
						fullWidth
						freeSolo
						getOptionLabel={(option) => option.url}
						onChange={(e, newValue) => {
							const urlValue =
								typeof newValue === "object" &&
								newValue !== null
									? newValue.url
									: newValue;
							setNewUrl(urlValue);
						}}
						renderInput={(params) => (
							<TextField
								{...params}
								type="url"
								labelId="scraper-select-label"
								id="scraper-select"
								value={newUrl}
								onChange={(e) => setNewUrl(e.target.value)}
								displayEmpty
								size="small"
								placeholder="Enter a URL to scrape"
								autoComplete="off"
							/>
						)}
					/>

					<LoadingButton
						variant="contained"
						color="primary"
						className="w-1/3"
						type="submit"
						startIcon={<FontAwesomeIcon icon={faEarthAmericas} />}
						disabled={
							!newUrl ||
							scrapedUrls.some((urlObj) => urlObj.url === newUrl)
						}
						loading={loadingUrl}
						// loadingPosition="start"
					>
						Scrape
					</LoadingButton>
				</form>
			</Card>
		</Box>
	);
}
